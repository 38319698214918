import { template as template_ed25d9b4bf134c968f7bf713c9fa3964 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_ed25d9b4bf134c968f7bf713c9fa3964(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_ed25d9b4bf134c968f7bf713c9fa3964(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_ed25d9b4bf134c968f7bf713c9fa3964(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
