import { template as template_13b44233703c46809ef471fffc0f0b51 } from "@ember/template-compiler";
const FKLabel = template_13b44233703c46809ef471fffc0f0b51(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
