import { template as template_33983e9ba1e1488bb5dd6005607fd5af } from "@ember/template-compiler";
const FKText = template_33983e9ba1e1488bb5dd6005607fd5af(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
