import { template as template_ea2827ee28db495ca19194a932e31be2 } from "@ember/template-compiler";
const WelcomeHeader = template_ea2827ee28db495ca19194a932e31be2(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
